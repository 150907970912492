import React from 'react';
import '../../styles/utility/ComingSoon.css';
import comingSoonBg from '../../assets/coming-soon-bg.gif';  // Import the GIF

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div 
        className="coming-soon-background"
        style={{ backgroundImage: `url(${comingSoonBg})` }}  // Use the imported GIF
      ></div>
      <div className="coming-soon-text">
        <h1>COMING SOON</h1>
      </div>
    </div>
  );
};

export default ComingSoon;