import React, { useState, useEffect } from 'react';
import Venue from './Venue';
import Preloader from '../utility/Preloader';
import '../../styles/venues/VenuesPage.css';
import '../../styles/venues/Venue.css';
import LoadingWrapper from '../utility/LoadingWrapper';

const VenuesPage = () => {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://nightsync-f2616a6594a6.herokuapp.com/venues')
      .then(response => response.json())
      .then(data => {
        setVenues(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching venues:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <LoadingWrapper>
      <div className="venues-page">
        <h2 className="venues-header">Venues</h2>
        <div className="venue-grid">
          {venues.map((venue) => (
            <Venue
              key={venue.venue_id}
              logo={venue.logo}
              name={venue.name}
              district={venue.neighborhood_or_district}
              venue_id={venue.venue_id}
            />
          ))}
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default VenuesPage;
