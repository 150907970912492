import React, { useState, useEffect} from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import Venue from '../venues/Venue';
import '../../styles/events/EventDetailPage.css';
import '../../styles/venues/Venue.css';
import Preloader from '../utility/Preloader';
import Nothing from '../utility/Nothing';  // Import the Nothing component
import LoadingWrapper from '../utility/LoadingWrapper';
import ColorThief from 'color-thief-browser';  // Add this import
import { FaArrowCircleLeft } from 'react-icons/fa';

const EventDetailPage = () => {
  const [event, setEvent] = useState(null);
  const [venue, setVenue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [buttonGradient, setButtonGradient] = useState('');
  const [buttonTextColor, setButtonTextColor] = useState('white');
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchEventAndVenue = async () => {
      try {
        const eventResponse = await fetch(`https://nightsync-f2616a6594a6.herokuapp.com/events/${id}`);
        if (!eventResponse.ok) {
          throw new Error('Event not found');
        }
        const eventData = await eventResponse.json();
        setEvent(eventData);

        const venueResponse = await fetch(`https://nightsync-f2616a6594a6.herokuapp.com/venues/${eventData.venue_id}`);
        if (!venueResponse.ok) {
          throw new Error('Venue not found');
        }
        const venueData = await venueResponse.json();
        setVenue(venueData);
        

        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchEventAndVenue();
  }, [id]);

  useEffect(() => {
    if (event?.flyer_poster_image) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = event.flyer_poster_image;
      
      img.onload = () => {
        const colorThief = new ColorThief();
        const dominantColor = colorThief.getColor(img);
        
        // Brighten the dominant color
        const brighterColor = dominantColor.map(c => Math.min(255, c + 50));
        
        // Check if color is close to white
        const isNearWhite = brighterColor.every(c => c > 200);
        setButtonTextColor(isNearWhite ? 'black' : 'white');
        
        // Randomize gradient direction and balance
        const angle = Math.floor(Math.random() * 360);
        const colorStop = Math.floor(Math.random() * 30) + 35;
        
        // Create a gradient using the brighter color as dominant
        const gradient = `
          linear-gradient(
            ${angle}deg, 
            rgb(${brighterColor.join(',')}) 0%, 
            rgb(${brighterColor.join(',')}) ${colorStop}%, 
            rgba(0, 0, 0, 0.8) 100%
          )
        `;
        setButtonGradient(gradient);
      };
    }
  }, [event?.flyer_poster_image]);

  if (loading) {
    return <Preloader />;
  }

  if (error || !event || !venue) {
    return <Nothing message="Event not found" />;  // Use Nothing component for 404
  }

  const formatDateTime = (date, time) => {
    // Create a date string in local time
    const dateStr = date.split('T')[0];
    
    // Create date object with explicit year, month, day
    const [year, month, day] = dateStr.split('-').map(num => parseInt(num));
    const eventDate = new Date(year, month - 1, day); // month is 0-based in JS Date
    
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric'
    };
    
    const formattedDate = eventDate.toLocaleDateString('en-US', options);
    
    // Format time
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? 'pm' : 'am';
    const hour12 = hour % 12 || 12;
    const formattedTime = `${hour12}:${minutes.padStart(2, '0')} ${ampm}`;
    
    return `${formattedDate} at ${formattedTime}`;
  };

  return (
    <LoadingWrapper>
      <div className="event-detail-page" style={{ position: 'relative', minHeight: '100vh' }}>
        <div className="event-detail-card" style={{ position: 'relative'}}>
          <button 
            className="event-detail-button" 
            onClick={() => navigate('/events')}
            style={{ background: buttonGradient, color: buttonTextColor }}
          >
            <span className="button-text" style={{ fontFamily: 'League Spartan', fontWeight: 'bold' }}>Back to Events</span>
            <FaArrowCircleLeft className="button-arrow" />
          </button>
          <div className="event-detail-content">
            <div className="event-detail-left-column">
              <div className="event-detail-header">
                <h1 className="event-detail-title">{event.title}</h1>
                <p className="event-detail-datetime">{formatDateTime(event.date, event.time)}</p>
                <p className="event-detail-description">{event.description}</p>
              </div>
              <a 
                href={event.ticket_link} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="event-detail-ticket-button"
                style={{ background: buttonGradient, color: buttonTextColor }}
              >
                Buy Tickets
              </a>
              <h2 className="event-detail-venue-title">Venue</h2>
              <div className="event-detail-venue-container">
                <Venue
                  logo={venue.logo}
                  name={venue.name}
                  district={venue.neighborhood_or_district}
                  venue_id={venue.venue_id}
                />
              </div>
            </div>
            <div className="event-detail-right-column">
              {event.flyer_poster_image && (
                <div className="event-detail-flyer-container">
                  <img src={event.flyer_poster_image} alt={`${event.title} flyer`} className="event-detail-flyer" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default EventDetailPage;
