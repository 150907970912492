import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/venues/Venue.css';

const VenueCard = ({ logo, name, district, venue_id }) => {
  const cardRef = useRef(null);

  const handleMouseMove = (e) => {
    if (!cardRef.current) return;
    
    const card = cardRef.current;
    const rect = card.getBoundingClientRect();
    
    // Calculate ratio from -1 to 1
    const ratioX = (e.clientX - rect.left) / rect.width * 2 - 1;
    const ratioY = (e.clientY - rect.top) / rect.height * 2 - 1;

    // Update CSS custom properties
    card.style.setProperty('--ratio-x', ratioX);
    card.style.setProperty('--ratio-y', ratioY);

    // Apply 3D rotation with "lifted" effect
    card.style.transform = `
      perspective(1000px)
      translateY(-5px)
      rotateY(${ratioX * 20}deg)
      rotateX(${-ratioY * 20}deg)
      scale3d(1.05, 1.05, 1.05)
    `;
  };

  const handleMouseLeave = () => {
    if (!cardRef.current) return;
    
    const card = cardRef.current;
    
    // Reset ratios
    card.style.setProperty('--ratio-x', 0);
    card.style.setProperty('--ratio-y', 0);
    
    // Reset transform with smooth transition back
    card.style.transform = `
      perspective(1000px)
      translateY(0)
      rotateX(0deg)
      rotateY(0deg)
      scale3d(1, 1, 1)
    `;
  };

  return (
    <Link to={`/venues/${venue_id}`}>
      <div className="scene">
        <div 
          ref={cardRef}
          className="venue-card"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <div className="venue-logo">
            <img src={logo} alt={`${name} logo`} />
          </div>
          <div className="venue-info">
            <div className="venue-name">{name}</div>
            <div className="venue-district">{district}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default VenueCard;
