import React from 'react';
import { Link } from 'react-router-dom';
import {FaInstagram, FaTiktok, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import '../../styles/footer/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Logo Section */}
        <div className="footer-section">
          <Link to="/" className="footer-logo">
            <span className="footer-logo-text">NIGHTSYNC</span>
          </Link>
          <p className="footer-tagline">Find Your Nightlife Destinations</p>
        </div>

        {/* Navigation Links - Will be hidden on mobile */}
        <div className="footer-section hide-mobile">
          <nav className="footer-nav">
            <Link to="/about" className="footer-nav-link">ABOUT</Link>
            <Link to="/venues" className="footer-nav-link">VENUES</Link>
            <Link to="/live" className="footer-nav-link">LIVE</Link>
            <Link to="/events" className="footer-nav-link">EVENTS</Link>
          </nav>
        </div>

        {/* Social Media */}
        <div className="footer-section">
          <h3>Connect With Us</h3>
          <div className="social-links">
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaXTwitter />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </div>
        </div>

        {/* Contact Section */}
        <div className="footer-section">
          <h3>Contact Us</h3>
          <a href="mailto:support@nightsync.live" className="footer-email-link">
            support@nightsync.live
          </a>
          <h3>Careers</h3>
          <a href="mailto:recruitment@nightsync.live" className="footer-email-link">
            recruitment@nightsync.live
          </a>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <div className="footer-legal">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms of Service</Link>
          </div>
          <div className="footer-copyright">
            © {new Date().getFullYear()} NightSync. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;