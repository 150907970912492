import React, { useState, useEffect } from 'react';
import StarBackground from './StarBackground';
import '../../styles/utility/Preloader.css';

const Preloader = ({ duration = 3000, onDone }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        if (onDone) onDone();
      }, 500);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onDone]);

  return (
    <div className={`preloader ${fadeOut ? 'fade-out' : ''}`}>
      <StarBackground/>
      <div className="preloader-logo glitch" data-text="NIGHTSYNC">
        NIGHTSYNC
      </div>
    </div>
  );
};

export default Preloader;
