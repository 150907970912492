import React, { useState, useEffect } from 'react';
import { Heading } from '@chakra-ui/react';
import AdminEventPage from './AdminEventPage';
import AdminVenuePage from './AdminVenuePage';
import '../../styles/admin/AdminPage.css';

const AdminPage = () => {
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'events');

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  return (
    <div className="admin-page">
      <Heading as="h1" size="xl" textAlign="center" mb={6}>Admin Page</Heading>
      <div className="tabs">
        <button className={`tab ${activeTab === 'events' ? 'active' : ''}`} onClick={() => setActiveTab('events')}>Events</button>
        <button className={`tab ${activeTab === 'venues' ? 'active' : ''}`} onClick={() => setActiveTab('venues')}>Venues</button>
      </div>
      
      {activeTab === 'events' && <AdminEventPage />}
      {activeTab === 'venues' && <AdminVenuePage />}
    </div>
  );
};

export default AdminPage;
