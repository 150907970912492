import { useEffect, useRef } from 'react';
import _ from 'lodash';

const debounce = _.debounce;

/**
 * Create an animated star background using canvas
 */
class Stars {
    constructor(el, options) {
      this.canvas = el;
      this.ctx = this.canvas.getContext('2d');
      this.options = Object.assign({
        stars: 100,  // Increase the number of stars
        colorRange: [0,60,240],
        speed: 3,  // Increase the speed slightly
        onlyDraw: false,
        density: 800
      }, options);
      this.move = true;
      this.skipFrame = false;
      this.options.densityStars = this.options.stars;
      this.stars = [];
  
      this.animationFrame = null; // Add this line
      this.start();
    }
  
    start() {
      this.canvas.height = window.innerHeight;
      this.canvas.width = window.innerWidth;
      this.autoDensity();
      this.addStars();
      this.draw();
      
      window.addEventListener('resize', debounce(e => {
        this.restart();
      }, 100));
    }
    
    restart() {
      window.cancelAnimationFrame(this.drawFrame);
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.canvas.height = window.innerHeight;
      this.canvas.width = window.innerWidth;
      this.stars.length = 0;
      this.autoDensity();
      this.addStars();
      this.draw();
    }
  
    addStars() {
      for (var i = 0; i < this.options.densityStars; i++) {
        let x = Math.random() * this.canvas.offsetWidth,
            y = Math.random() * this.canvas.offsetHeight,
            radius = Math.random() * 2,
            hue = this.options.colorRange[getRandom(0, this.options.colorRange.length - 1)],
            sat = getRandom(50, 100),
            alpha = Math.max(Math.random(), 0.05);

        this.stars.push(new Star(this.canvas, {
          x: x,
          y: y,
          hue: hue,
          sat: sat,
          radius: radius,
          alpha: alpha,
          speed: this.options.speed  // Remove the duplicate 'speed' key
        }));
      }
    }
  
    autoDensity() {
      /* calc area */
      let area = this.canvas.width * this.canvas.height / 1000;
  
      /* calc number of particles based on density area */
      let numberParticles = area * this.options.stars / this.options.density;
  
      /* add or remove X particles */
      let missingParticles = this.options.stars - numberParticles;
  
      if (missingParticles < 0) this.options.densityStars = this.options.stars + Math.abs(missingParticles);
      else this.options.densityStars = this.options.stars - Math.abs(missingParticles);
  }
  
    draw() {
      // also skip every other frame, will still run at ~30 frames a second
      if ((this.move && !this.skipFrame) || this.options.onlyDraw) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  
        const length = this.stars.length;
        for (let i = 0; i < length; i++) {
          const star = this.stars[i];
          star.move();
          star.draw();
        }
      }
  
      this.skipFrame = !this.skipFrame;
      if(!this.options.onlyDraw) {
        this.animationFrame = window.requestAnimationFrame(() => { this.draw(); });
      }
    }
  
    // Add this method
    stop() {
      if (this.animationFrame) {
        window.cancelAnimationFrame(this.animationFrame);
      }
    }
  }
  
  function getRandom(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }		
  
  class Star {
    constructor(canvas, options) {
      this.canvas = canvas
      this.ctx = canvas.getContext('2d');
      this.options = options;
      this.radius = this.options.radius;
      this.x = this.rx = this.options.x;
      this.y = this.ry = this.options.y;
      this.setVelocity();
    }
  
    setVelocity() {
      this.vx = 0;
      const alpha = this.options.alpha;
      if(alpha > 0 && alpha < .4) this.vy = -.1;
      else if(alpha >= .4 && alpha < .7) this.vy = -.3;
      else this.vy = -.5;
    }
  
    draw() {
      this.ctx.beginPath();
      this.ctx.arc(this.rx, this.ry, this.radius, 0, Math.PI * 2);
      this.ctx.fillStyle = `rgb(255, 255, 255)`;
      this.ctx.fill();
    }
  
    move() {
      const p = this,
            ms = this.options.speed / 2;
      
      p.x += p.vx * ms;
      p.y += p.vy * ms;
  
      /* change particle position if it is out of canvas */
      if (p.y + p.radius <= 0) {
        p.y = p.canvas.height;
      }
  
      p.rx = Math.round(p.x);
      p.ry = Math.round(p.y);
    }
  }
  

export function useStarBackground() {
  const canvasRef = useRef(null);
  const starsRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      if (starsRef.current) {
        starsRef.current.restart();  // Restart stars when resizing
      }
    };

    resizeCanvas();
    window.addEventListener('resize', debounce(resizeCanvas, 200));

    starsRef.current = new Stars(canvas);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      if (starsRef.current) {
        starsRef.current.stop();
      }
    };
  }, []);

  return canvasRef;
}
