import React from 'react';
import '../../styles/utility/Nothing.css';
// Import the background image
import nothingBg from '../../assets/dog-snoop.gif'; // Adjust the path as needed

const Nothing = ({ message = "404 - Page Not Found" }) => {
  return (
    <div className="nothing-container">
      <div 
        className="nothing-background"
        style={{ backgroundImage: `url(${nothingBg})` }}
      ></div>
      <div className="nothing-text">
        <h1>{message}</h1>
        <p>Sorry we didn't find what you were looking for... So here's Snoop Dogg.</p>
      </div>
    </div>
  );
};

export default Nothing;
