import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { LuDice5 } from "react-icons/lu";
import Venue from '../../components/venues/Venue';
import Event from '../../components/events/Event';
import home from '../../assets/home_gifs/home.gif';
import home1 from '../../assets/home_gifs/home1.gif';
import home2 from '../../assets/home_gifs/home2.gif';
import home3 from '../../assets/home_gifs/home3.gif';
import home4 from '../../assets/home_gifs/home4.gif';
import home5 from '../../assets/home_gifs/home5.gif';
import home6 from '../../assets/home_gifs/home6.gif';
import home7 from '../../assets/home_gifs/home7.gif';
import home8 from '../../assets/home_gifs/home8.gif';
import home9 from '../../assets/home_gifs/home9.gif';
import home10 from '../../assets/home_gifs/home10.gif';
import home11 from '../../assets/home_gifs/home11.gif';
import home12 from '../../assets/home_gifs/home12.gif';
import home13 from '../../assets/home_gifs/home13.gif';
import home14 from '../../assets/home_gifs/home14.gif';
import home15 from '../../assets/home_gifs/home15.gif';
import home16 from '../../assets/home_gifs/home16.gif';
import '../../styles/home/HomePage.css';
import { motion, AnimatePresence } from 'framer-motion';

const HomePage = () => {
  const [randomVenue, setRandomVenue] = useState(null);
  const [randomEvent, setRandomEvent] = useState(null);
  const [isRandomizing, setIsRandomizing] = useState(false);
  const [gif1, setGif1] = useState(home);
  const [gif2, setGif2] = useState(home);
  const [isGif1Active, setIsGif1Active] = useState(true);
  const [unusedGifs, setUnusedGifs] = useState([
    home, home1, home2, home3, home4, home5, home6, home7, 
    home8, home9, home10, home11, home12, home13, home14, home15, home16
  ]);

  const getRandomGif = useCallback(() => {
    if (unusedGifs.length === 0) {
      setUnusedGifs([
        home, home1, home2, home3, home4, home5, home6, home7,
        home8, home9, home10, home11, home12, home13, home14, home15, home16
      ]);
      return home;
    }

    const randomIndex = Math.floor(Math.random() * unusedGifs.length);
    const selectedGif = unusedGifs[randomIndex];
    
    setUnusedGifs(prev => prev.filter((_, index) => index !== randomIndex));
    
    return selectedGif;
  }, [unusedGifs]);

  const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = reject;
      img.src = url;
    });
  };

  const changeGif = useCallback(async () => {
    try {
      const newGif = getRandomGif();
      // Update the inactive GIF and then switch
      if (isGif1Active) {
        setGif2(newGif);
      } else {
        setGif1(newGif);
      }
      setIsGif1Active(!isGif1Active);
    } catch (error) {
      console.error('Failed to change GIF:', error);
    }
  }, [getRandomGif, isGif1Active]);

  useEffect(() => {
    const gifInterval = setInterval(changeGif, 7000);
    return () => clearInterval(gifInterval);
  }, [changeGif]);

  // Also preload all GIFs on initial load
  useEffect(() => {
    const preloadAllGifs = async () => {
      const allGifs = [
        home, home1, home2, home3, home4, home5, home6, home7,
        home8, home9, home10, home11, home12, home13, home14, home15, home16
      ];
      
      try {
        await Promise.all(allGifs.map(preloadImage));
      } catch (error) {
        console.error('Failed to preload GIFs:', error);
      }
    };

    preloadAllGifs();
  }, []);

  const fetchRandomData = async () => {
    setIsRandomizing(true);
    try {
      // Fetch venues
      const venuesResponse = await fetch('https://nightsync-f2616a6594a6.herokuapp.com/venues');
      const venues = await venuesResponse.json();
      
      // Fetch events 
      const eventsResponse = await fetch('https://nightsync-f2616a6594a6.herokuapp.com/events');
      const events = await eventsResponse.json();

      // Select random venue and event
      const randomVenueIndex = Math.floor(Math.random() * venues.length);
      const randomEventIndex = Math.floor(Math.random() * events.length);

      setRandomVenue(venues[randomVenueIndex]);
      setRandomEvent(events[randomEventIndex]);
    } catch (error) {
      console.error('Error fetching random data:', error);
    } finally {
      setTimeout(() => setIsRandomizing(false), 500);
    }
  };

  useEffect(() => {
    fetchRandomData();
  }, []);

  return (
    <div className="home-container">
      <section className="hero-section">
        <div 
          className="background-gif"
          style={{ 
            backgroundImage: `url(${gif1})`,
            opacity: isGif1Active ? 1 : 0
          }}
        />
        <div 
          className="background-gif"
          style={{ 
            backgroundImage: `url(${gif2})`,
            opacity: isGif1Active ? 0 : 1
          }}
        />
        <div className="text-overlay">
          <h1>Your ultimate guide to nightlife</h1>
        </div>
      </section>

      <section className="navigation-section">
        <button 
          className="randomize-button" 
          onClick={fetchRandomData}
          disabled={isRandomizing}
        >
          <LuDice5 className={isRandomizing ? 'spinning' : ''} /> 
          Randomize
        </button>

        <div className="nav-container">
          <div className="nav-column">
            <div className="card-container">
              <div className="live-card-skeleton">
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-circle"></div>
              </div>
            </div>
            <Link to="/live" className="nav-button">Live Data</Link>
          </div>

          <div className="nav-column">
            <div className="card-container">
              <AnimatePresence mode="wait">
                {randomVenue && (
                  <motion.div
                    key={randomVenue.venue_id}
                    initial={{ rotateY: -90 }}
                    animate={{ rotateY: 0 }}
                    exit={{ rotateY: 90 }}
                    transition={{ duration: 0.1, ease: "easeOut" }}
                    className="motion-div-wrapper"
                  >
                    <Venue 
                      {...randomVenue}
                      name={randomVenue.name}
                      district={randomVenue.neighborhood_or_district} 
                      venue_id={randomVenue.venue_id}
                      logo={randomVenue.logo}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <Link to="/venues" className="nav-button">See Venues</Link>
          </div>

          <div className="nav-column">
            <div className="card-container">
              <AnimatePresence mode="wait">
                {randomEvent && (
                  <motion.div
                    key={randomEvent.event_id}
                    initial={{ rotateY: -90 }}
                    animate={{ rotateY: 0 }}
                    exit={{ rotateY: 90 }}
                    transition={{ duration: 0.1, ease: "easeOut" }}
                    style={{ width: '100%' }}
                  >
                    <Event {...randomEvent} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <Link to="/events" className="nav-button">See Events</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;