import React, { useState, useEffect, useRef } from 'react';
import Event from './Event';
import '../../styles/events/EventsPage.css';
import Preloader from '../utility/Preloader';
import LoadingWrapper from '../utility/LoadingWrapper';
import { useStarBackground } from '../../hooks/useStarBackground';

const EventRow = ({ title, events }) => {
  const scrollRef = useRef(null);

  const handleScroll = (direction) => {
    const container = scrollRef.current;
    const scrollAmount = container.clientWidth;
    
    if (direction === 'right') {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    } else {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }

    // Check if we need to loop back to the start/end
    setTimeout(() => {
      if (container.scrollLeft === 0 && direction === 'left') {
        container.scrollLeft = container.scrollWidth / 2;
      } else if (container.scrollLeft >= container.scrollWidth / 2 && direction === 'right') {
        container.scrollLeft = 0;
      }
    }, 500); // Adjust this timeout to match your scroll animation duration
  };

  return (
    <div className="event-row">
      <h2>{title}</h2>
      <div className="event-list-container">
        <button className="scroll-button left" onClick={() => handleScroll('left')}>&lt;</button>
        <div className="event-list" ref={scrollRef}>
          {events.concat(events).map((event, index) => (
            <Event key={`${event.id}-${index}`} {...event} />
          ))}
        </div>
        <button className="scroll-button right" onClick={() => handleScroll('right')}>&gt;</button>
      </div>
    </div>
  );
};

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const canvasRef = useStarBackground();

  useEffect(() => {
    fetch('https://nightsync-f2616a6594a6.herokuapp.com/events')
      .then(response => response.json())
      .then(data => {
        setEvents(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching events:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Preloader />;
  }
  return (
    <LoadingWrapper>
      <div className="events-page" style={{ position: 'relative', minHeight: '100vh' }}>
        <canvas 
          ref={canvasRef} 
          style={{ 
            position: 'fixed', 
            top: 0, 
            left: 0, 
            width: '100%', 
            height: '100%', 
            zIndex: 2,
            pointerEvents: 'none'
          }} 
        />
        <div style={{ position: 'relative', zIndex: 2 }}>
          <EventRow title="Today" events={events} />
          <EventRow title="Popular" events={events} />
          <EventRow title="This Month" events={events} />
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default EventsPage;
