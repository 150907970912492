import React from 'react';
import ComingSoon from '../utility/ComingSoon';

const LivePage = () => {
  return (
    <div className="live-page">
      <ComingSoon />
    </div>
  );
};

export default LivePage;