import React, { useState } from 'react';
import Preloader from './Preloader';

const LoadingWrapper = ({ children }) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Preloader duration={1200} onDone={() => setLoading(false)} />}
      <div style={{ display: loading ? 'none' : 'block' }}>
        {children}
      </div>
    </>
  );
};

export default LoadingWrapper;
