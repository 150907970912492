import React from 'react';
import ComingSoon from '../utility/ComingSoon';

const AboutPage = () => {
  return (
    <div className="about-page"> 
      <ComingSoon />
    </div>
  );
};

export default AboutPage;