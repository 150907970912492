import React, { useState, useEffect } from 'react';
import {
  Heading,
  Text,
  Button,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import '../../styles/admin/AdminEventPage.css';

const AdminEventPage = () => {
  const [eventData, setEventData] = useState({
    title: '',
    date: '',
    time: '',
    venue_id: '',
    description: '',
    flyer_poster_image: '',
    ticket_link: ''
  });

  const [events, setEvents] = useState([]);
  const [venues, setVenues] = useState([]);
  const [editingEvent, setEditingEvent] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchEvents();
    fetchVenues();
  }, []);

  const fetchEvents = async () => {
    const response = await fetch('https://nightsync-f2616a6594a6.herokuapp.com/events');
    const data = await response.json();
    setEvents(data);
  };

  const fetchVenues = async () => {
    const response = await fetch('https://nightsync-f2616a6594a6.herokuapp.com/venues');
    const data = await response.json();
    setVenues(data);
  };

  const handleEventChange = (e) => {
    setEventData({ ...eventData, [e.target.name]: e.target.value });
  };

  const handleEditEventChange = (e) => {
    const { name, value } = e.target;
    setEditingEvent(prev => {
      const updated = { ...prev, [name]: value };
      return updated;
    });
  };

  const submitEvent = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://nightsync-f2616a6594a6.herokuapp.com/events', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(eventData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const newEvent = await response.json();
      setEvents([...events, newEvent]);
      setEventData({
        title: '',
        date: '',
        time: '',
        venue_id: '',
        description: '',
        flyer_poster_image: '',
        ticket_link: ''
      });
      alert('Event added successfully!');
    } catch (error) {
      console.error('Error creating event:', error);
      alert('Failed to add event. Please try again.');
    }
  };

  const deleteEvent = async (id) => {
    try {
      await fetch(`https://nightsync-f2616a6594a6.herokuapp.com/events/${id}`, {
        method: 'DELETE',
      });
      setEvents(events.filter(event => event.event_id !== id));
      alert('Event deleted successfully!');
    } catch (error) {
      console.error('Error deleting event:', error);
      alert('Failed to delete event. Please try again.');
    }
  };

  const openEditModal = (event) => {
    const eventCopy = { ...event };
    
    // Ensure all required fields are present
    const requiredFields = ['event_id', 'title', 'date', 'time', 'venue_id', 'description', 'flyer_poster_image', 'ticket_link'];
    requiredFields.forEach(field => {
      if (!eventCopy[field]) eventCopy[field] = '';
    });
    
    // Format the date and time for the input fields
    if (eventCopy.date) {
      eventCopy.date = new Date(eventCopy.date).toISOString().split('T')[0];
    }
    if (eventCopy.time) {
      eventCopy.time = eventCopy.time.substring(0, 5); // Assuming time is in HH:MM format
    }
    setEditingEvent(eventCopy);
    onOpen();
  };

  const submitEditEvent = async (e) => {
    e.preventDefault();
    try {

      // First, delete the existing event
      const deleteUrl = `https://nightsync-f2616a6594a6.herokuapp.com/events/${editingEvent.event_id}`;
      const deleteResponse = await fetch(deleteUrl, {
        method: 'DELETE',
      });

      if (!deleteResponse.ok) {
        throw new Error(`Failed to delete existing event. Status: ${deleteResponse.status}`);
      }

      // Then, create a new event with the updated data
      const createUrl = 'https://nightsync-f2616a6594a6.herokuapp.com/events';
      const createResponse = await fetch(createUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: editingEvent.title,
          description: editingEvent.description,
          date: editingEvent.date,
          time: editingEvent.time,
          venue_id: editingEvent.venue_id,
          ticket_link: editingEvent.ticket_link,
          flyer_poster_image: editingEvent.flyer_poster_image
        }),
      });

      if (!createResponse.ok) {
        throw new Error(`Failed to create updated event. Status: ${createResponse.status}`);
      }

      const updatedEvent = await createResponse.json();

      setEvents(events.map(event => event.event_id === editingEvent.event_id ? updatedEvent : event));
      onClose();
      alert('Event updated successfully!');
    } catch (error) {
      alert(`Failed to update event. Error: ${error.message}`);
    }
  };

  return (
    <div className="admin-event-page">
      <form onSubmit={submitEvent}>
        <Heading as="h2" size="lg" mb={4}>Create Event</Heading>
        <input className="input-field" placeholder="Title" name="title" value={eventData.title} onChange={handleEventChange} />
        <input className="input-field" type="date" name="date" value={eventData.date} onChange={handleEventChange} />
        <input className="input-field" type="time" name="time" value={eventData.time} onChange={handleEventChange} />
        <select className="input-field" name="venue_id" value={eventData.venue_id} onChange={handleEventChange}>
          <option value="">Select a venue</option>
          {venues.map(venue => (
            <option key={venue.venue_id} value={venue.venue_id}>{venue.name}</option>
          ))}
        </select>
        <textarea className="input-field" placeholder="Description" name="description" value={eventData.description} onChange={handleEventChange}></textarea>
        <input className="input-field" placeholder="Flyer/Poster URL" name="flyer_poster_image" value={eventData.flyer_poster_image} onChange={handleEventChange} />
        <input className="input-field" placeholder="Ticket Link" name="ticket_link" value={eventData.ticket_link} onChange={handleEventChange} />
        <Button type="submit" colorScheme="blue">Create Event</Button>
      </form>

      <Heading as="h2" size="lg" mt={8} mb={4}>All Events</Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {events.map(event => (
          <Card key={event.event_id} bg="#2a2a2a" color="white">
            <CardHeader>
              <Heading size="md">{event.title}</Heading>
            </CardHeader>
            <CardBody>
              <Text>Date: {event.date}</Text>
              <Text>Time: {event.time}</Text>
              <Text>Venue: {venues.find(v => v.venue_id === event.venue_id)?.name || 'Unknown'}</Text>
              <Text>Description: {event.description}</Text>
              {event.flyer_poster_image && <Text>Flyer URL: {event.flyer_poster_image}</Text>}
              {event.ticket_link && <Text>Ticket Link: {event.ticket_link}</Text>}
            </CardBody>
            <CardFooter>
              <Button colorScheme="blue" mr={2} onClick={() => openEditModal(event)}>Edit</Button>
              <Button colorScheme="red" onClick={() => deleteEvent(event.event_id)}>Delete</Button>
            </CardFooter>
          </Card>
        ))}
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Event</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {editingEvent && (
              <form onSubmit={submitEditEvent}>
                <input className="input-field" placeholder="Title" name="title" value={editingEvent.title || ''} onChange={handleEditEventChange} />
                <input className="input-field" type="date" name="date" value={editingEvent.date || ''} onChange={handleEditEventChange} />
                <input className="input-field" type="time" name="time" value={editingEvent.time || ''} onChange={handleEditEventChange} />
                <select className="input-field" name="venue_id" value={editingEvent.venue_id || ''} onChange={handleEditEventChange}>
                  <option value="">Select a venue</option>
                  {venues.map(venue => (
                    <option key={venue.venue_id} value={venue.venue_id}>{venue.name}</option>
                  ))}
                </select>
                <textarea className="input-field" placeholder="Description" name="description" value={editingEvent.description || ''} onChange={handleEditEventChange}></textarea>
                <input className="input-field" placeholder="Flyer/Poster URL" name="flyer_poster_image" value={editingEvent.flyer_poster_image || ''} onChange={handleEditEventChange} />
                <input className="input-field" placeholder="Ticket Link" name="ticket_link" value={editingEvent.ticket_link || ''} onChange={handleEditEventChange} />
                <Button type="submit" colorScheme="blue" mt={4}>Update Event</Button>
              </form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AdminEventPage;
