import React, { useState, useEffect } from 'react';
import {
  Heading,
  Text,
  Button,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Grid,
  GridItem,
  Select,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import '../../styles/admin/AdminVenuePage.css';
import { TimeIcon } from '@chakra-ui/icons';

const AdminVenuePage = () => {
  const textColor = useColorModeValue('white', 'white');
  const iconColor = useColorModeValue('white', 'white');
  
  const [venueData, setVenueData] = useState({
    logo: '',
    name: '',
    neighborhood_or_district: '',
    address: '',
    city: '',
    hours: {
      mon: { status: 'open', open: '', close: '' },
      tues: { status: 'open', open: '', close: '' },
      wed: { status: 'open', open: '', close: '' },
      thurs: { status: 'open', open: '', close: '' },
      fri: { status: 'open', open: '', close: '' },
      sat: { status: 'open', open: '', close: '' },
      sun: { status: 'open', open: '', close: '' }
    },
    website: '',
    phone_number: '',
    description: '',
    gallery: ['', '', '', '', '']
  });

  const [venues, setVenues] = useState([]);
  const [editingVenue, setEditingVenue] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchVenues();
  }, []);

  const fetchVenues = async () => {
    const response = await fetch('https://nightsync-f2616a6594a6.herokuapp.com/venues');
    const data = await response.json();
    setVenues(data);
  };

  const handleVenueChange = (e) => {
    const { name, value } = e.target;
    setVenueData({
      ...venueData,
      [name]: value,
    });
  };

  const handleEditVenueChange = (e) => {
    const { name, value } = e.target;
    setEditingVenue({
      ...editingVenue,
      [name]: value,
    });
  };

  const handleHoursChange = (day, field, value) => {
    setVenueData(prevData => ({
      ...prevData,
      hours: {
        ...prevData.hours,
        [day]: {
          ...prevData.hours[day],
          [field]: value
        }
      }
    }));
  };

  const handleEditHoursChange = (day, field, value) => {
    setEditingVenue(prevData => ({
      ...prevData,
      hours: {
        ...prevData.hours,
        [day]: {
          ...prevData.hours[day],
          [field]: value
        }
      }
    }));
  };

  const submitVenue = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://nightsync-f2616a6594a6.herokuapp.com/venues', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(venueData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const newVenue = await response.json();
      setVenues([...venues, newVenue]);
      setVenueData({
        logo: '',
        name: '',
        neighborhood_or_district: '',
        address: '',
        city: '',
        hours: {
          mon: { status: 'open', open: '', close: '' },
          tues: { status: 'open', open: '', close: '' },
          wed: { status: 'open', open: '', close: '' },
          thurs: { status: 'open', open: '', close: '' },
          fri: { status: 'open', open: '', close: '' },
          sat: { status: 'open', open: '', close: '' },
          sun: { status: 'open', open: '', close: '' }
        },
        website: '',
        phone_number: '',
        description: '',
        gallery: ['', '', '', '', '']
      });
      alert('Venue added successfully!');
    } catch (error) {
      console.error('Error creating venue:', error);
      alert('Failed to add venue. Please try again.');
    }
  };

  const deleteVenue = async (id) => {
    try {
      await fetch(`https://nightsync-f2616a6594a6.herokuapp.com/events/venue/${id}`, {
        method: 'DELETE',
      });
      await fetch(`https://nightsync-f2616a6594a6.herokuapp.com/venues/${id}`, {
        method: 'DELETE',
      });
      setVenues(venues.filter(venue => venue.venue_id !== id));
    } catch (error) {
      console.error('Error deleting venue:', error);
      alert('Failed to delete venue. Please try again.');
    }
  };

  const openEditModal = (venue) => {
    console.log('Original venue:', venue);
    
    const venueCopy = JSON.parse(JSON.stringify(venue));
    
    // If gallery is a string (JSON), parse it
    if (typeof venueCopy.gallery === 'string') {
      try {
        venueCopy.gallery = JSON.parse(venueCopy.gallery);
      } catch (e) {
        console.log('Error parsing gallery:', e);
        venueCopy.gallery = ['', '', '', '', ''];
      }
    }
    
    // Ensure gallery is an array with 5 elements
    if (!Array.isArray(venueCopy.gallery)) {
      venueCopy.gallery = ['', '', '', '', ''];
    }
    while (venueCopy.gallery.length < 5) {
      venueCopy.gallery.push('');
    }
    
    console.log('Prepared venue for editing:', venueCopy);
    setEditingVenue(venueCopy);
    onOpen();
  };

  const submitEditVenue = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting venue data:', editingVenue);

      const updateUrl = `https://nightsync-f2616a6594a6.herokuapp.com/venues/${editingVenue.venue_id}`;
      const updateResponse = await fetch(updateUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          logo: editingVenue.logo,
          name: editingVenue.name,
          neighborhood_or_district: editingVenue.neighborhood_or_district,
          address: editingVenue.address,
          city: editingVenue.city,
          hours: editingVenue.hours,
          website: editingVenue.website,
          phone_number: editingVenue.phone_number,
          description: editingVenue.description,
          gallery: editingVenue.gallery
        }),
      });

      if (!updateResponse.ok) {
        const errorText = await updateResponse.text();
        throw new Error(`Failed to update venue. Status: ${updateResponse.status}, Message: ${errorText}`);
      }

      const updatedVenue = await updateResponse.json();
      console.log('Server response:', updatedVenue);

      setVenues(venues.map(venue => venue.venue_id === editingVenue.venue_id ? updatedVenue : venue));
      onClose();
      alert('Venue updated successfully!');
    } catch (error) {
      console.error('Error updating venue:', error);
      alert(`Failed to update venue. Error: ${error.message}`);
    }
  };

  const formatHours = (hours) => {
    if (typeof hours !== 'object') return 'Hours not available';
    return Object.entries(hours).map(([day, time]) => {
      if (time.status === '24hours') return `${day.charAt(0).toUpperCase() + day.slice(1)}: Open 24 hours`;
      if (time.status === 'closed') return `${day.charAt(0).toUpperCase() + day.slice(1)}: Closed`;
      return `${day.charAt(0).toUpperCase() + day.slice(1)}: ${time.open} - ${time.close}`;
    }).join(', ');
  };

  const renderHoursInput = (day, isEditing = false) => {
    const data = isEditing ? editingVenue : venueData;
    const handleChange = isEditing ? handleEditHoursChange : handleHoursChange;

    return (
      <GridItem key={day}>
        <Text fontWeight="bold" color={textColor}>{day.charAt(0).toUpperCase() + day.slice(1)}</Text>
        <Select 
          size="sm" 
          value={data.hours[day].status} 
          onChange={(e) => handleChange(day, 'status', e.target.value)}
          color={textColor}
        >
          <option value="open">Open</option>
          <option value="closed">Closed</option>
          <option value="24hours">24 Hours</option>
        </Select>
        {data.hours[day].status === 'open' && (
          <Flex alignItems="center" mt={2}>
            <TimeIcon mr={2} color={iconColor} />
            <Select 
              size="sm" 
              value={data.hours[day].open} 
              onChange={(e) => handleChange(day, 'open', e.target.value)}
              mr={2}
              color={textColor}
            >
              {[...Array(24)].map((_, i) => (
                <option key={i} value={`${i.toString().padStart(2, '0')}:00`}>
                  {`${i.toString().padStart(2, '0')}:00`}
                </option>
              ))}
            </Select>
            <Text mx={2} color={textColor}>to</Text>
            <Select 
              size="sm" 
              value={data.hours[day].close} 
              onChange={(e) => handleChange(day, 'close', e.target.value)}
              color={textColor}
            >
              {[...Array(24)].map((_, i) => (
                <option key={i} value={`${i.toString().padStart(2, '0')}:00`}>
                  {`${i.toString().padStart(2, '0')}:00`}
                </option>
              ))}
            </Select>
          </Flex>
        )}
      </GridItem>
    );
  };

  return (
    <div className="admin-venue-page">
      <form onSubmit={submitVenue}>
        <Heading as="h2" size="lg" mb={4}>Create Venue</Heading>
        <SimpleGrid columns={2} spacing={4}>
          <input className="input-field" placeholder="Logo URL" name="logo" value={venueData.logo} onChange={handleVenueChange} />
          <input className="input-field" placeholder="Name" name="name" value={venueData.name} onChange={handleVenueChange} />
          <input className="input-field" placeholder="District" name="neighborhood_or_district" value={venueData.neighborhood_or_district} onChange={handleVenueChange} />
          <input className="input-field" placeholder="Address" name="address" value={venueData.address} onChange={handleVenueChange} />
          <input className="input-field" placeholder="City" name="city" value={venueData.city} onChange={handleVenueChange} />
          <input className="input-field" placeholder="Website" name="website" value={venueData.website} onChange={handleVenueChange} />
          <input className="input-field" placeholder="Phone Number" name="phone_number" value={venueData.phone_number} onChange={handleVenueChange} />
        </SimpleGrid>
        
        <FormControl mt={4}>
          <FormLabel>Gallery Images (URLs)</FormLabel>
          <SimpleGrid columns={1} spacing={2}>
            {venueData.gallery.map((url, index) => (
              <input
                key={index}
                className="input-field"
                placeholder={`Gallery Image ${index + 1} URL`}
                value={url}
                onChange={(e) => {
                  const newGallery = [...venueData.gallery];
                  newGallery[index] = e.target.value;
                  setVenueData({ ...venueData, gallery: newGallery });
                }}
              />
            ))}
          </SimpleGrid>
        </FormControl>
        
        <FormControl mt={4}>
          <FormLabel>Hours</FormLabel>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            {['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun'].map((day) => renderHoursInput(day))}
          </Grid>
        </FormControl>
        
        <textarea className="input-field" placeholder="Description" name="description" value={venueData.description} onChange={handleVenueChange}></textarea>
        <Button type="submit" colorScheme="blue">Create Venue</Button>
      </form>
      
      <Heading as="h2" size="lg" mt={8} mb={4}>All Venues</Heading>
      <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(300px, 1fr))'>
        {venues.map(venue => (
          <Card key={venue.venue_id} variant="outline">
            <CardHeader>
              <Heading size="md">{venue.name}</Heading>
            </CardHeader>
            <CardBody>
              <Text>Address: {venue.address}</Text>
              <Text>District: {venue.neighborhood_or_district}</Text>
              <Text>Hours: {formatHours(venue.hours)}</Text>
              <Text>Website: {venue.website}</Text>
              <Text>Phone: {venue.phone_number}</Text>
              <Text>Description: {venue.description}</Text>
            </CardBody>
            <CardFooter>
              <Button colorScheme="blue" mr={2} onClick={() => openEditModal(venue)}>Edit</Button>
              <Button colorScheme="red" onClick={() => deleteVenue(venue.venue_id)}>Delete</Button>
            </CardFooter>
          </Card>
        ))}
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg="gray.800">
          <ModalHeader color={textColor}>Edit Venue</ModalHeader>
          <ModalCloseButton color={textColor} />
          <ModalBody>
            {editingVenue && (
              <form onSubmit={submitEditVenue}>
                <SimpleGrid columns={2} spacing={4}>
                  <input className="input-field" placeholder="Logo URL" name="logo" value={editingVenue.logo} onChange={handleEditVenueChange} style={{color: 'black'}} />
                  <input className="input-field" placeholder="Name" name="name" value={editingVenue.name} onChange={handleEditVenueChange} style={{color: 'black'}} />
                  <input className="input-field" placeholder="District" name="neighborhood_or_district" value={editingVenue.neighborhood_or_district} onChange={handleEditVenueChange} style={{color: 'black'}} />
                  <input className="input-field" placeholder="Address" name="address" value={editingVenue.address} onChange={handleEditVenueChange} style={{color: 'black'}} />
                  <input className="input-field" placeholder="City" name="city" value={editingVenue.city} onChange={handleEditVenueChange} style={{color: 'black'}} />
                  <input className="input-field" placeholder="Website" name="website" value={editingVenue.website} onChange={handleEditVenueChange} style={{color: 'black'}} />
                  <input className="input-field" placeholder="Phone Number" name="phone_number" value={editingVenue.phone_number} onChange={handleEditVenueChange} style={{color: 'black'}} />
                </SimpleGrid>

                <FormControl mt={4}>
                  <FormLabel color={textColor}>Gallery Images (URLs)</FormLabel>
                  <SimpleGrid columns={1} spacing={2}>
                    {editingVenue.gallery.map((url, index) => (
                      <input
                        key={index}
                        className="input-field"
                        placeholder={`Gallery Image ${index + 1} URL`}
                        value={url}
                        onChange={(e) => {
                          const newGallery = [...editingVenue.gallery];
                          newGallery[index] = e.target.value;
                          setEditingVenue({ ...editingVenue, gallery: newGallery });
                        }}
                        style={{color: 'black'}}
                      />
                    ))}
                  </SimpleGrid>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel color={textColor}>Hours</FormLabel>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    {['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun'].map((day) => renderHoursInput(day, true))}
                  </Grid>
                </FormControl>

                <textarea 
                  className="input-field" 
                  placeholder="Description" 
                  name="description" 
                  value={editingVenue.description} 
                  onChange={handleEditVenueChange} 
                  style={{color: 'black'}}
                ></textarea>
                
                <Button type="submit" colorScheme="blue" mt={4}>Update Venue</Button>
              </form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AdminVenuePage;
