import React, { useEffect } from 'react';
import '../../styles/utility/StarBackground.css';

const generateStarShadows = (count) => {
  let shadows = [];
  for (let i = 0; i < count; i++) {
    shadows.push(`${Math.random() * 2000}px ${Math.random() * 2000}px #FFF`);
  }
  return shadows.join(', ');
};

const StarBackground = () => {
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--star-shadows-small', generateStarShadows(1250));
    root.style.setProperty('--star-shadows-medium', generateStarShadows(350));
    root.style.setProperty('--star-shadows-big', generateStarShadows(175));
  }, []);

  return (
    <div className="star-background">
      <div className="stars" style={{'--star-shadows': 'var(--star-shadows-small)'}}></div>
      <div className="stars2" style={{'--star-shadows': 'var(--star-shadows-medium)'}}></div>
      <div className="stars3" style={{'--star-shadows': 'var(--star-shadows-big)'}}></div>
    </div>
  );
};

export default StarBackground;
