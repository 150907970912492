import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ColorThief from 'color-thief-browser';
import '../../styles/events/Event.css';

const Event = ({ event_id, title, time, date, venue_name, ticket_link, flyer_poster_image, isVenuePage = false }) => {
  const [buttonGradient, setButtonGradient] = useState('');
  const [boxShadowColor, setBoxShadowColor] = useState('');
  const eventCardRef = useRef(null);

  useEffect(() => {
    if (flyer_poster_image) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = flyer_poster_image;
      
      img.onload = () => {
        const colorThief = new ColorThief();
        const dominantColor = colorThief.getColor(img);
        
        // Randomize gradient direction and balance
        const angle = Math.floor(Math.random() * 360);
        const colorStop = Math.floor(Math.random() * 30) + 35; // Random value between 35% and 65%
        
        // Create a gradient from the dominant color to black
        const gradient = `
          linear-gradient(
            ${angle}deg, 
            rgb(${dominantColor.join(',')}) 0%, 
            rgb(${dominantColor.join(',')}) ${colorStop}%, 
            rgba(0, 0, 0, 0.8) 100%
          )
        `;
        setButtonGradient(gradient);

        // Set box shadow color with higher opacity and brightness
        const brighterColor = dominantColor.map(c => Math.min(255, c + 50)); // Increase brightness
        setBoxShadowColor(`rgba(${brighterColor.join(',')}, 0.6)`);
      };
    }
  }, [flyer_poster_image]);

  useEffect(() => {
    if (eventCardRef.current && boxShadowColor) {
      eventCardRef.current.style.setProperty('--box-shadow-color', boxShadowColor);
    }
  }, [boxShadowColor]);

  // Add new useEffect for hover animation
  useEffect(() => {
    const card = eventCardRef.current;
    if (!card) return;

    const handleMouseMove = (e) => {
      const { left, top, width, height } = card.getBoundingClientRect();
      const x = (e.clientX - left) / width;
      const y = (e.clientY - top) / height;

      requestAnimationFrame(() => {
        card.style.setProperty('--ratio-x', x);
        card.style.setProperty('--ratio-y', y);
      });
    };

    const handleMouseEnter = () => {
      card.addEventListener('mousemove', handleMouseMove);
    };

    const handleMouseLeave = () => {
      card.removeEventListener('mousemove', handleMouseMove);
      card.style.setProperty('--ratio-x', 0.5);
      card.style.setProperty('--ratio-y', 0.5);
    };

    card.addEventListener('mouseenter', handleMouseEnter);
    card.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      card.removeEventListener('mouseenter', handleMouseEnter);
      card.removeEventListener('mouseleave', handleMouseLeave);
      card.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const formatDateTime = (date, time) => {
    // Create a date string in local time
    const dateStr = date.split('T')[0];
    
    // Create date object with explicit year, month, day
    const [year, month, day] = dateStr.split('-').map(num => parseInt(num));
    const eventDate = new Date(year, month - 1, day); // month is 0-based in JS Date
    
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric'
    };
    
    const formattedDate = eventDate.toLocaleDateString('en-US', options);
    
    // Format time
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? 'pm' : 'am';
    const hour12 = hour % 12 || 12;
    const formattedTime = `${hour12}:${minutes.padStart(2, '0')} ${ampm}`;
    
    return `${formattedDate} at ${formattedTime}`;
  };

  const formattedDate = formatDateTime(date, time);

  return (
    <Link 
      to={`/events/${event_id}`} 
      state={{ event: { event_id, title, time, date, venue_name, ticket_link, flyer_poster_image } }}
      className="event-card"
      ref={eventCardRef}
      style={{ '--box-shadow-color': boxShadowColor }}
    >
      <div className="event-image" style={{ backgroundImage: `url(${flyer_poster_image || 'https://via.placeholder.com/280x260'})` }}></div>
      <div className="event-info">
        <div className="event-title">{title}</div>
        <div className="event-datetime">{formattedDate}</div>
        <div className="event-venue">{venue_name}</div>
      </div>
      {ticket_link && (
        <div className="ticket-container">
          <button className="event-ticket-button" style={{ background: buttonGradient }}>
          Tickets
          </button>
        </div>
      )}
    </Link>
  );
};

export default Event;
