import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import '../../styles/navbar/Navbar.css';
import { IoChevronDownSharp } from 'react-icons/io5';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const { isAuthenticated, logout } = useAuth0();
  console.log('User is authenticated:', isAuthenticated);

  return (
    <nav className="navbar">
      <div className="navbar-section">
        <Link to="/about" className="nav-item">ABOUT</Link>
        <Link to="/venues" className="nav-item">VENUES</Link>
        {isAuthenticated && (
          <Link to="/admin" className="nav-item">Dashboard</Link>
        )}
      </div>
      <div className="logo-container">
        <Link to="/" className="nav-item logo">
          <span className="navbar-logo">NIGHTSYNC</span>
        </Link>
      </div>
      <div className="navbar-section">
        {isAuthenticated && (
          <span onClick={() => logout({ returnTo: window.location.origin })} className="nav-item" style={{ cursor: 'pointer' }}>Log Out</span>
        )}
        <Link to="/live" className="nav-item">LIVE</Link>
        <Link to="/events" className="nav-item">EVENTS</Link>
      </div>
      {isMobile && (
        <>
          <button 
            className={`menu-icon ${isOpen ? 'open' : ''}`} 
            onClick={toggleMenu}
          >
            <IoChevronDownSharp 
              size={24} 
              style={{ 
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
                transition: 'transform 0.3s ease'
              }}
            />
          </button>
          <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
            <Link to="/about" className="nav-item" onClick={toggleMenu}>ABOUT</Link>
            <Link to="/venues" className="nav-item" onClick={toggleMenu}>VENUES</Link>
            <Link to="/live" className="nav-item" onClick={toggleMenu}>LIVE</Link>
            <Link to="/events" className="nav-item" onClick={toggleMenu}>EVENTS</Link>
            {isAuthenticated && (
              <>
                <Link to="/admin" className="nav-item" onClick={toggleMenu}>Dashboard</Link>
                <span 
                  onClick={() => {
                    logout({ returnTo: window.location.origin });
                    toggleMenu();
                  }} 
                  className="nav-item"
                >
                  Log Out
                </span>
              </>
            )}
          </div>
        </>
      )}
    </nav>
  );
};

export default Navbar;