import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Navbar from './components/navbar/Navbar';
import HomePage from './components/home/HomePage';
import EventsPage from './components/events/EventsPage';
import AboutPage from './components/about/AboutPage';
import LivePage from './components/live/LivePage';
import VenuesPage from './components/venues/VenuesPage';
import VenueDetailPage from './components/venues/VenueDetailPage';
import EventDetailPage from './components/events/EventDetailPage';
import AdminPage from './components/admin/AdminPage';
import ProtectedRoute from './components/admin/ProtectedRoute';
import './styles/App.css';
import Preloader from './components/utility/Preloader';
import StarBackground from './components/utility/StarBackground';
import Footer from './components/footer/Footer';


function App() {
  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPreloader(false);
    }, 2500); // Show preloader for 2.5 seconds

    return () => clearTimeout(timer);
  }, []);

  if (showPreloader) {
    return <Preloader />;
  }

  return (
    <ChakraProvider>
      <Router>
        <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <StarBackground />
          <Navbar />
          <div className="content" style={{ flex: 1, overflowY: 'auto' }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/events" element={<EventsPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/live" element={<LivePage />} />
              <Route path="/venues" element={<VenuesPage />} />
              <Route path="/venues/:id" element={<VenueDetailPage />} />
              <Route path="/events/:id" element={<EventDetailPage />} />
              <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;