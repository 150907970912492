import React from 'react';
import { Link } from 'react-router-dom';

const VenueEventCard = ({ event_id, title, time, date, flyer_poster_image }) => {
  const formatDateTime = (date, time) => {
    // Create a date string in local time
    const dateStr = date.split('T')[0];
    
    // Create date object with explicit year, month, day
    const [year, month, day] = dateStr.split('-').map(num => parseInt(num));
    const eventDate = new Date(year, month - 1, day); // month is 0-based in JS Date
    
    const formattedDate = eventDate.toLocaleDateString('en-US', { 
      weekday: 'short',
      month: 'short', 
      day: 'numeric' 
    });
    
    // Convert time to 12-hour format
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    const formattedTime = `${hour12}:${minutes} ${ampm}`;

    return `${formattedDate} · ${formattedTime}`;
  };

  return (
    <Link to={`/events/${event_id}`} className="venue-event-card">
      <div 
        className="venue-event-image" 
        style={{ backgroundImage: `url(${flyer_poster_image})` }}
      />
      <div className="venue-event-info">
        <div className="venue-event-title">{title}</div>
        <div className="venue-event-datetime">{formatDateTime(date, time)}</div>
      </div>
    </Link>
  );
};

export default VenueEventCard;