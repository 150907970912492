import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import VenueEventCard from './VenueEventCard';  // Add this import
import '../../styles/venues/VenueDetailPage.css'; // You can create a custom CSS file for styling
import Preloader from '../utility/Preloader';
import Nothing from '../utility/Nothing';  // Import the Nothing component
import LoadingWrapper from '../utility/LoadingWrapper';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const VenueDetailPage = () => {
  const { id } = useParams(); // Get the venue ID from the URL
  const [venue, setVenue] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true); // State to handle loading status
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchVenueAndEvents = async () => {
      try {
        // Fetch venue data
        const venueResponse = await fetch(`https://nightsync-f2616a6594a6.herokuapp.com/venues/${id}`);
        if (!venueResponse.ok) {
          throw new Error('Venue not found');
        }
        const venueData = await venueResponse.json();
        setVenue(venueData);

        // Fetch events data
        try {
          const eventsResponse = await fetch(`https://nightsync-f2616a6594a6.herokuapp.com/events/venue/${id}`);
          if (!eventsResponse.ok) {
            throw new Error('Failed to fetch events');
          }
          const eventsData = await eventsResponse.json();
          console.log('Events data:', eventsData); // Add this line for debugging
          setEvents(eventsData);
        } catch (error) {
          console.error('Error fetching events:', error);
          setError(error.message);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching venue:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchVenueAndEvents();
  }, [id]);

  const handleImageClick = () => {
    if (!venue?.gallery?.length) return;
    
    setCurrentImageIndex(prevIndex => {
      const nextIndex = prevIndex + 1;
      return nextIndex >= venue.gallery.length ? 0 : nextIndex;
    });
  };

  if (loading) {
    return <Preloader />;
  }

  if (error || !venue) {
    return <Nothing message="Venue not found" />;  // Use Nothing component for 404
  }

  const formatHours = (hours) => {
    if (!hours || typeof hours !== 'object') return 'Hours not available';
    
    const weekdays = ['sun', 'mon', 'tues', 'wed', 'thurs', 'fri', 'sat'];
    const dayLabels = {
      'sun': 'Sunday',
      'mon': 'Monday', 
      'tues': 'Tuesday',
      'wed': 'Wednesday',
      'thurs': 'Thursday',
      'fri': 'Friday',
      'sat': 'Saturday'
    };
  
    return weekdays.map(day => {
      const timeObject = hours[day];
      const fullDay = dayLabels[day];
      
      if (!timeObject) {
        return (
          <div key={day} className="hours-row">
            <span className="day-label">{fullDay}:</span>
            <span className="time-label closed">Closed</span>
          </div>
        );
      }

      if (timeObject.status === '24hours') {
        return (
          <div key={day} className="hours-row">
            <span className="day-label">{fullDay}:</span>
            <span className="time-label open">24 Hours</span>
          </div>
        );
      }

      if (timeObject.status === 'closed') {
        return (
          <div key={day} className="hours-row">
            <span className="day-label">{fullDay}:</span>
            <span className="time-label closed">Closed</span>
          </div>
        );
      }
      
      const { open, close } = timeObject;
      const formattedOpen = formatTime(open);
      const formattedClose = formatTime(close);
  
      return (
        <div key={day} className="hours-row">
          <span className="day-label">{fullDay}:</span>
          <span className="time-label open">{`${formattedOpen} - ${formattedClose}`}</span>
        </div>
      );
    });
  };
  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const parsedHours = parseInt(hours, 10);
    const ampm = parsedHours >= 12 ? 'PM' : 'AM';
    const formattedHours = parsedHours % 12 || 12;
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  const formatAddress = (address) => {
    return address.replace(/ /g, '+');
  };

  return (
    <LoadingWrapper>
      <div className="venue-detail-page">
        <div className="venue-detail-card">
          {/* Logo Row */}
          <img src={venue.logo} alt={`${venue.name} logo`} className="venue-detail-logo" />
          
          {/* First Row: Info and Gallery */}
          <div className="venue-detail-info-row">
            <div className="venue-detail-info">
              <h1 className="venue-detail-name">{venue.name}</h1>
              <p className="venue-detail-location">{venue.city}</p>
              <p className="venue-detail-description">{venue.description}</p>
            </div>
            <div className="venue-detail-gallery">
              <div className="gallery-main-container">
                {venue.gallery?.length > 0 && (
                  <img 
                    src={venue.gallery[currentImageIndex]} 
                    alt={`${venue.name} gallery ${currentImageIndex + 1}`}
                    className="gallery-main-image"
                    onClick={handleImageClick}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </div>
            </div>
          </div>
  
          {/* Events Row */}
          <div className="venue-detail-events-row">
            <h2 className="venue-detail-events-title">Upcoming Events</h2>
            <div className="venue-detail-event-cards">
              {events.map(event => (
                <VenueEventCard
                  key={event.event_id}
                  event_id={event.event_id}
                  title={event.title}
                  time={event.time}
                  date={event.date}
                  flyer_poster_image={event.flyer_poster_image}
                />
              ))}
            </div>
          </div>
  
          {/* Footer Row */}
          <div className="venue-detail-footer">
            <div className="venue-detail-hours">
              <h3>Hours:</h3>
              <div className="hours-grid">
                {formatHours(venue.hours)}
              </div>
            </div>
            
            <div className="venue-detail-right-footer">
              <a 
                href={`https://www.google.com/maps/search/?api=1&query=${formatAddress(venue.address)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="venue-detail-address"
              >
                {venue.address}
              </a>
              <div className="venue-detail-map">
                <iframe
                  title="Venue Location Map"
                  frameBorder="0"
                  src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(venue.address)}`}
                  allowFullScreen
                ></iframe>
              </div>
              <div className="venue-detail-contact">
                <span>{venue.phone_number}</span>
                <span className="separator">|</span>
                <a href={`https://${venue.website}`} target="_blank" rel="noopener noreferrer">
                  {venue.website}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default VenueDetailPage;
